<template>
  <div>
    <PageHeader title="Dashboard" />
    <div class="content">
      <div class="alert alert-info">
        <i class="fa fa-info-circle mr-1"></i>
        We are currently working on a new version of the dashboard. If there's anything you would like to see on it, let us know using the question
        mark in the bottom right.
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import PageHeader from '@/components/PageHeader';

export default {
  name: 'Home',
  components: {
    PageHeader
  },
  computed: {
    ...mapGetters({
      loadingAction: 'user/loadingAction',
      dashboardData: 'user/dashboardData'
    })
  }
};
</script>
