var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('PageHeader', {
    attrs: {
      "title": "Dashboard"
    }
  }), _vm._m(0)], 1);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "content"
  }, [_c('div', {
    staticClass: "alert alert-info"
  }, [_c('i', {
    staticClass: "fa fa-info-circle mr-1"
  }), _vm._v(" We are currently working on a new version of the dashboard. If there's anything you would like to see on it, let us know using the question mark in the bottom right. ")])]);
}]

export { render, staticRenderFns }